import React from 'react'
import image1 from '../../../assets/images/story/truelayer/truelayer-1.png'
import image2 from '../../../assets/images/story/truelayer/truelayer-2.png'
import image3 from '../../../assets/images/story/truelayer/truelayer-4.png'
import image4 from '../../../assets/images/story/truelayer/truelayer-3.png'
import image5 from '../../../assets/images/story/truelayer/truelayer-slack.png'
import image6 from '../../../assets/images/story/truelayer/truelayer-metrics.png'
import silvia from '../../../assets/images/story/truelayer/silvia-di-nardo.png'
import maryanna from '../../../assets/images/story/truelayer/maryanna-rayko.png'
import luca from '../../../assets/images/story/truelayer/luca-martinetti.png'
import michael from '../../../assets/images/story/truelayer/michael-slack.png'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'
import QuoteBlock from '../QuoteBlock'

const TrueLayerBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <>
            <p>
              TrueLayer is Europe’s leading open banking payments network and
              one of the fastest-growing fintech companies in the world. Founded
              in 2016, the company powers smart, safe, and fast payments for
              businesses of all sizes.
            </p>
            <a
              target="\_blank"
              rel="noopener noreferrer"
              href="https://truelayer.com/"
            >
              truelayer.com
            </a>
          </>
        ),
        location: <p>Headquartered in London, operations across Europe</p>,
        headcount: <p>350+ employees, 130+ engineers</p>,
        customers: <p>2023</p>,
      }}
      main={
        <>
          <p>
            Maryanna Rayko leads TrueLayer’s 100-person product engineering
            organization, who are in charge of building and maintaining Europe’s
            leading open banking payments network. As the VP of Engineering, one
            of Maryanna’s main goals is to introduce more structure to the
            engineering organization so they can scale more effectively.
          </p>
          <p>
            As a part of the initiative to improve efficiency across the
            engineering organization, Maryanna started rolling out three types
            of metrics:
          </p>
          <ul>
            <li>Product metrics</li>
            <li>Operational metrics</li>
            <li>Productivity metrics</li>
          </ul>
          <p>
            While product and operational metrics were relatively easy to
            surface with a combination of internal systems and tools like
            OpsLevel, the productivity aspect turned out to be the trickiest to
            measure. TrueLayer had previously tried (the now-discontinued)
            Athenian but had abandoned it because it offered little to no
            transparency into how different metrics were calculated, resulting
            in the engineers and managers becoming reluctant to engage with the
            tool.
          </p>
          <QuoteBlock
            quote="When it came to productivity, I was looking for something that would help me empower the teams to improve. I’m really keen for our engineers and managers to use the tool because I want to help the teams help themselves. I do need access to the data, but my main objective is that teams can improve their own productivity."
            name="Maryanna Rayko"
            title="VP of Engineering"
            photo={maryanna}
          />
          <p>
            In addition to empowering teams, Maryanna was also interested in
            understanding the cost of KTLO (keeping the lights on) to make
            informed decisions about prioritizing and addressing tech debt as
            well as other KTLO reducing initiatives.
          </p>
          <p>
            CTO Luca Martinetti, on the other hand, needed to measure
            engineering investment and understand the return on that investment.
            This became especially important as the engineering industry at
            large entered a cost-efficiency mode due to rising interest rates.
          </p>
          <QuoteBlock
            quote="Like many other tech companies, we now operate with more constraints. We need to make engineering really count and choose what we invest in more carefully. At this stage, it’s important for us to get visibility into where teams are spending their time and whether they’re struggling somehow."
            name="Luca Martinetti"
            title="CTO"
            photo={luca}
          />

          <h2>
            Increasing visibility at every level of the engineering organization
          </h2>

          <p>
            Maryanna and the managers in the product engineering organization
            started looking into tools that would help solve their two key
            objectives: empowering teams to make better decisions and
            understanding the return on engineering investment.
          </p>
          <p>
            From the first meeting with Swarmia, Maryanna understood that there
            was perfect philosophical alignment between how she thinks about the
            problem space and how Swarmia approaches solving those problems.
          </p>
          <QuoteBlock
            quote="I immediately liked what I saw. Swarmia aligns with our way of thinking about the importance of empowering teams and checks all the boxes I have for a tool like this."
            name="Maryanna Rayko"
            title="VP of Engineering"
            photo={maryanna}
          />
          <h3>Measuring the return on engineering investment</h3>
          <p>
            For Maryanna, Luca, and the rest of the engineering leadership team,
            the investment balance view in Swarmia answers many of the questions
            they have about where teams are spending their time and whether
            there are opportunities to allocate engineering effort better.
          </p>
          <ProductScreenshot src={image1} />
          <QuoteBlock
            quote="From the start, it was important that the investment balance would reflect reality. We make a lot of decisions based on the data, so we need to be able to trust it."
            name="Maryanna Rayko"
            title="VP of Engineering"
            photo={maryanna}
          />
          <p>
            The investment balance view gives the engineering leaders
            much-needed visibility into how much time different teams spend
            keeping the lights on. TrueLayer’s investment category setup in
            Swarmia also allows them to see the amount of the previous year’s
            feature work that has rolled over to the new year, which helps them
            improve planning and prioritization going forward.
          </p>
          <p>
            The Initiatives view offers another valuable lens into product
            development work, allowing engineering leaders to quickly see the
            status and progress of ongoing cross-team initiatives so they can
            proactively jump in and support the teams that may need it.
          </p>
          <QuoteBlock
            quote="It’s possible that some teams have more workload than they can handle. When that happens, we want to be able to offer them help quickly — and Swarmia helps us do just that."
            name="Luca Martinetti"
            title="CTO"
            photo={luca}
          />
          <ProductScreenshot src={image2} />
          <p>
            Currently, the TrueLayer team is evaluating the feature and
            comparing it to Jira Advanced Roadmaps. While a wider rollout has
            yet to happen, many of the engineering managers already prefer
            Swarmia’s Initiatives view as they find it easier to use than Jira’s
            corresponding solution.
          </p>

          <h3>Enabling managers to eliminate blockers</h3>
          <p>
            Silvia Di Nardo, a Senior Engineering Manager in TrueLayer’s product
            engineering organization, says Swarmia has quickly opened the doors
            to many valuable conversations in and between teams.
          </p>
          <QuoteBlock
            quote="Swarmia allows us to see what we’re spending our time on. This makes it simpler for teams to understand the reasons behind project delays, leading to overall improvements in planning effectiveness."
            name="Silvia Di Nardo"
            title="Senior Engineering Manager"
            photo={silvia}
          />
          <p>
            Engineering Manager Michael Slack has found a lot of value in
            Swarmia’s Working Agreements, as they provide a solution to
            something he’s been struggling with as a leader: keeping the team
            accountable to the targets they set for themselves.
          </p>
          <ProductScreenshot src={image3} />
          <QuoteBlock
            quote="Working Agreements allow us to see that we’re trending in the right direction on everything we consider important. The team likes being held accountable to the decisions we’re making."
            name="Michael Slack"
            title="Engineering Manager"
            photo={michael}
          />
          <p>
            Michael’s team has also started to regularly review their Work Log
            in Swarmia to see all ongoing work at a glance, identify patterns,
            and assess their progress.
          </p>
          <ProductScreenshot src={image4} />
          <QuoteBlock
            quote="A big problem for my team is that we work with many legacy services. Swarmia has allowed us to prove that those legacy services influence our lead time and cycle time. Now, when we make bigger changes, we can easily prove their impact."
            name="Michael Slack"
            title="Engineering Manager"
            photo={michael}
          />
          <h3>
            Empowering engineers with better insights and automated feedback
            loops
          </h3>
          <p>
            While Maryanna and the engineering managers spend a lot of time in
            the Swarmia app, the engineers at TrueLayer primarily engage with
            the platform through its two-way Slack notifications. This way, the
            teams can automate some of the feedback loops that are crucial for
            their workflows.
          </p>
          <ProductScreenshot src={image5} />
          <QuoteBlock
            quote="Swarmia’s Slack notifications really drew the developers in. The unnecessary labor of going into Slack and saying, “can I get a review on this?” has gone away. I’ve heard a lot of positive feedback about that from the team."
            name="Michael Slack"
            title="Engineering Manager"
            photo={michael}
          />

          <h2>
            81% adoption in three months and unique value for everyone in the
            organizations
          </h2>
          <p>
            In the first three months of using Swarmia, TrueLayer has seen
            impressive adoption numbers, with 81% of the engineering
            organization fully onboarded. Over half of the engineers have also
            started using Swarmia’s Slack notifications. As a result, after the
            first quarter, 69% of the users rated their overall experience with
            Swarmia as either good or excellent.
          </p>
          <p>
            Additionally, the teams in the product engineering organization have
            seen impressive productivity improvements over the first three
            months of actively using the tool. Namely, they’ve cut 18% of their
            cycle times, improved their already remarkable code review rate by
            2%, and reduced time to first review by 30%.
          </p>
          <ProductScreenshot src={image6} />
          <QuoteBlock
            quote="What I really like about Swarmia is that everyone in the product engineering organization uses it in different ways. They all get value out of it, but that value changes based on their role."
            name="Silvia Di Nardo"
            title="Senior Engineering Manager"
            photo={silvia}
          />
          <p>
            With the product adoption already at an exceptional level, Silvia
            expects it to increase even further as they’re moving their Swarmia
            login to Okta, allowing even more people at TrueLayer to get into
            Swarmia.
          </p>
          <QuoteBlock
            quote="We’re excited about the new Okta integration, as it allows all our product managers and other stakeholders who may not have GitHub credentials to access Swarmia."
            name="Silvia Di Nardo"
            title="Senior Engineering Manager"
            photo={silvia}
          />
          <p>
            The engineering leaders at TrueLayer are also looking forward to the
            launch of Swarmia’s <a href="/product/surveys/">survey</a> feature.
            Ultimately, it’ll allow them to correlate developer experience data
            and feedback from their engineers with the system metrics that are
            already available to them in Swarmia.
          </p>
        </>
      }
    />
  )
}
export default TrueLayerBody
